(function( $ ) {
    'use strict';

    $(document).ready(function() {
		//navigate menu with keyboard 

	    if ( !$('#header').hasClass('collapsed-header') ) {
	    	var selectedItem;
	    	// focus function (keyboards)
		    $('.navbar-nav .dropdown-toggle').on("focus",function(e) {
		        if ( $(this).hasClass('open') ) {
		        	$(this).siblings('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
					$(this).removeClass('open');
					$('.selected').removeClass('selected');
					selectedItem = null;
				} else {
					$(this).addClass('open');
		        	$(this).siblings('.dropdown-menu').first().stop(true, true).delay(150).slideDown();
				}
			});

	    	$('.dropdown').keydown( function(e) {
	    		
	    		var currentItem, next, prev;

	    		currentItem = $(this).children('ul').children('li');

	    		switch(e.which) {
	    	    	case 9: //tab - close menu
	    	    		if ( $(this).hasClass('dropdown') ) {
	    	    			if ( $(this).children('.dropdown-toggle').hasClass('open') ) {
	    	    				$(this).children('.dropdown-toggle').focus();
						    } else {
						    	return true;
						    }
	    	    		} else {
	    	    			if ( $(this).closest('.dropdown-menu').siblings('.dropdown-toggle').hasClass('open') ) {
	    	    				$(this).closest('.dropdown-menu').siblings('.dropdown-toggle').focus();
						    	return true;
						    }
	    	    		}
						break;

					case 37: // left - select parent list itemif ( selectedItem && selectedItem.children( '.dropdown-menu' ) ) {
				        if ( selectedItem && selectedItem.parents( '.dropdown-submenu' ) ) {
				            selectedItem.removeClass('selected');
				            next = selectedItem = selectedItem.parents( '.dropdown-submenu' ).eq(0);
				            if ( next.length > 0 ) {
				                selectedItem = next.addClass('selected');
				            } 
							selectedItem.find( '>a' ).focus();
				        }
	    	        	break;

					case 38: // up - open previous item in the current list
						if ( selectedItem ) {
				            selectedItem.removeClass('selected');
				            next = selectedItem.prev();
				            if ( next.length > 0 ) {
				                selectedItem = next.addClass('selected');
				            } else {
				                selectedItem = currentItem.last().addClass('selected');
				            }
				        } else {
				            selectedItem = currentItem.last().addClass('selected');
				        }
						selectedItem.find( '>a' ).focus();

						if ( currentItem.children( ".dropdown-menu" ).hasClass('open') ) {
    	        			currentItem.children( ".dropdown-menu" ).first().stop(true, true).delay(100).slideUp();
							currentItem.children( ".dropdown-menu" ).removeClass('open');
    	        		}

						if ( selectedItem.children( '.dropdown-menu' ) ) {
							if ( selectedItem.children( ".dropdown-menu" ).hasClass('open') ) {
					        	selectedItem.children( ".dropdown-menu" ).first().stop(true, true).delay(100).slideUp();
								selectedItem.children( ".dropdown-menu" ).removeClass('open');
							} else {
								selectedItem.children( ".dropdown-menu" ).addClass('open');
					        	selectedItem.children( ".dropdown-menu" ).first().stop(true, true).delay(150).slideDown();
							}
						}

						if ( selectedItem.parents( '.dropdown-submenu' ) ) {
							selectedItem.parents( '.dropdown-menu' ).addClass('open');
				        	selectedItem.parents( '.dropdown-menu' ).first().stop(true, true).delay(150).slideDown();
						}

	    	        	break;

	    	        case 39: // right - select first item in the next sub list
	    	        	if ( selectedItem && selectedItem.children( '.dropdown-menu' ) ) {
				            selectedItem.removeClass('selected');
				            next = selectedItem = selectedItem.children('ul').children('li').eq(0);
				            if ( next.length > 0 ) {
				                selectedItem = next.addClass('selected');
				            } 
							selectedItem.find( '>a' ).focus();
				        }
	    	        	break;

	    	        case 40: // down - open next item in the current list

	    	        	if ( selectedItem ) {
							selectedItem.removeClass('selected');
				            next = selectedItem.next();
				            if ( next.length > 0 ) {
				                selectedItem = next.addClass('selected');
				            } else {
				                selectedItem = currentItem.eq(0).addClass('selected');
				            }
						} else {
							selectedItem = currentItem.eq(0).addClass('selected');
						}

						selectedItem.find( '>a' ).focus();

						if ( currentItem.children( ".dropdown-menu" ).hasClass('open') ) {
    	        			currentItem.children( ".dropdown-menu" ).first().stop(true, true).delay(100).slideUp();
							currentItem.children( ".dropdown-menu" ).removeClass('open');
    	        		}

						if ( selectedItem.children( '.dropdown-menu' ) ) {
							if ( selectedItem.children( ".dropdown-menu" ).hasClass('open') ) {
					        	selectedItem.children( ".dropdown-menu" ).first().stop(true, true).delay(100).slideUp();
								selectedItem.children( ".dropdown-menu" ).removeClass('open');
							} else {
								selectedItem.children( ".dropdown-menu" ).addClass('open');
					        	selectedItem.children( ".dropdown-menu" ).first().stop(true, true).delay(150).slideDown();
							}
						}

						if ( selectedItem.parents( '.dropdown-submenu' ) ) {
							selectedItem.parents( '.dropdown-menu' ).addClass('open');
				        	selectedItem.parents( '.dropdown-menu' ).first().stop(true, true).delay(150).slideDown();
						}

	    	        	break;
	    	
	    	        default: return; // exit this handler for other keys
				}
				e.preventDefault(); // prevent the default action (scroll / move caret)
	    	});
	    }
		    
	});
})( jQuery );
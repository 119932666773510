(function($) {
  "use strict";

  /**
   * for new search header
   */
  function check_resize() {
    if (
      $(window).width() < 768 &&
      $(".header-search.collapsed-header").length > 0
    ) {
      $(".header-search").removeClass("collapsed-header");
    }
    if ($(window).width() > 767 && $(".header-search").length > 0) {
      $(".header-search").addClass("collapsed-header");
    }
  }

  function toggleNavMenu(event) {
    if (
      event.type === "focus" &&
      $(event.currentTarget).hasClass("navbar-toggle")
    ) {
      if ($("#header-main-row2.collapse").hasClass("in")) {
        $("#header-main-row2.collapse").removeClass("in");
      } else {
        $("#header-main-row2.collapse")
          .addClass("in")
          .attr("style", "");
      }
    } else {
      $(".header-original #govNavMenu").each(function() {
        if ($(this).is(":visible")) {
          $(this)
            .addClass("hidden")
            .removeAttr("style");
          $(this).removeClass("expanded");
        } else {
          $(this).addClass("expanded");
          $(this).removeClass("hidden");
        }
      });
    }
  }

  function toggleHeaderElements(event) {
    // Mobile only?
    if (
      event.type == "focus" &&
      $(event.currentTarget).hasClass("navbar-toggle")
    ) {
      if ($(event.currentTarget).hasClass("search-button")) {
        if ($("#wp-header-search.collapse").hasClass("in")) {
          $("#wp-header-search.collapse").removeClass("in");
        } else {
          $("#wp-header-search.collapse")
            .addClass("in")
            .attr("style", "");
          $("#wp-top-navbar.collapse").removeClass("in");
        }
        if ($(".top-search-form").length > 0) {
          $(".top-search-form").toggleClass("is-active");
        }
      } else if ($(event.currentTarget).hasClass("menu-button")) {
        if ($("#wp-top-navbar.collapse").hasClass("in")) {
          //$("#wp-top-navbar.collapse").removeClass("in");
          $(".search-button").attr("tabindex", 0);
        } else {
          $("#wp-top-navbar.collapse")
            //.addClass("in")
            .attr("style", "");
          $("#wp-header-search.collapse").removeClass("in");
          $(".search-button").attr("tabindex", 1);
        }
        if ($(".top-search-form").length > 0) {
          $(".top-search-form").removeClass("is-active");
        }
      }
    }
    // Non-mobile?
    else {
      // Collapsed header-specific behaviours
      if ($("#header").hasClass("collapsed-header")) {
        if ($(event.currentTarget).hasClass("search-button")) {
          // Close header-links section if it's open
          if ($("#wp-top-navbar").hasClass("in")) {
            $("#wp-top-navbar").removeClass("in");
          }
          if ($(".top-search-form").length > 0) {
            $(".top-search-form").toggleClass("is-active");
          }
        } else if ($(event.currentTarget).hasClass("menu-button")) {
          // Close search if it's open
          if ($(".header-search").hasClass("in")) {
            $(".header-search").removeClass("in");
          }
          if ($(".top-search-form").length > 0) {
            $(".top-search-form").removeClass("is-active");
          }
        }
      } else {
        if ($(event.currentTarget).hasClass("search-button")) {
          if ($(".top-search-form").length > 0) {
            $(".top-search-form").toggleClass("is-active");
          }
        }
        if ($(event.currentTarget).hasClass("menu-button")) {
          if ($(".top-search-form").length > 0) {
            $(".top-search-form").removeClass("is-active");
          }
        }
      }
    }
  }

  $(document).ready(function($) {
    var paddingHeightTop = $("#header-main").outerHeight();
    var paddingHeightBottom = $("#footerWrapper").height();
    check_resize();

    $(".search-trigger").on("click", function() {
      $('.top-search-form form[role="search"]').submit();
    });

    /** This was added and updated WD-749 **/
    if ($(window).width() > 767 && true) {
      $("header#header").css("padding-bottom", paddingHeightTop);
    }
    //$('.wrap').css('padding-top', paddingHeightTop);
    /** end update WD-749 **/

    $(".wrap").css("padding-bottom", paddingHeightBottom);

    $("#footerToggle > a").click(function(event) {
      event.preventDefault();
      toggleFooter(event);
    });

    //open/close menu when a user clicks, tabs over the govMainMenu
    /*
        $('.menu-button').on("focus mousedown",function(e) {
            alert('Nav menu!');
           	toggleNavMenu(e);
            e.preventDefault();
    	});*/

    //open/close menu when a user clicks, tabs over the govMainMenu
    $(".menu-button").on("focus mousedown", function(e) {
      toggleHeaderElements(e);
      //addScrollableBurgerMenu();
      e.preventDefault();
    });

    // Handler for when the user clicks on the search button (mobile and collapsed header views only)
    $(".search-button").on("focus mousedown", function(e) {
      toggleHeaderElements(e);
      e.preventDefault();
    });

    // added for floating back to top icon
    $(".back-to-top").on("focus mousedown", function(e) {
      e.preventDefault();
      $("html,body").animate({ scrollTop: 0 }, "slow");
    });

    if (Modernizr.touch && $(window).width() > 767) {
      $("#header").addClass("collapsed-header");
    }
  });

  $(window).on("resize", function() {
    check_resize();
  });
  var scrollTimer;

  $(window).on("scroll", function() {
    // Clear timeout if one is pending
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    // Set timeout
    scrollTimer = setTimeout(function() {
      /*
       * Re-position the "Back to top" button if it is touching the footer
       */

      if ($(window).scrollTop() > 0) {
        $(".back-to-top").show();
      } else {
        $(".back-to-top").hide();
      }

      // Check if the footer is within the viewport and switch the position of the button accordingly
      var windowBottomCoordinate = $(window).scrollTop() + $(window).height();
      if (windowBottomCoordinate > $("#footer").offset().top) {
        $(".back-to-top").addClass("footer-overlap");
      } else {
        $(".back-to-top").removeClass("footer-overlap");
      }

      /*
       * When the page is scrolled in desktop mode, collapse the header
       */

      var scrollPosition = $(window).scrollTop();
      if (!$("#header-main-tiles").hasClass("navbar-static-top")) {
        if (scrollPosition > 50 && $(window).width() >= 768) {
          if (!$(".header-original#header").hasClass("collapsed-header")) {
            $("#header-main > .container").hide();
            $("#header").addClass("collapsed-header");
            $("#header-main > .container").fadeIn("300");
          }
        } else {
          if (
            $(".header-original#header").hasClass("collapsed-header") &&
            !Modernizr.touch
          ) {
            $("#header-main > .container").hide();
            $("#header").removeClass("collapsed-header");
            $("#header-main > .container").fadeIn("300", function() {
              // After the full header is fully loaded, readjust the top padding on content
              //adjustContentPadding();
            });
          }
        }
      }
    }, 100); // Timeout in msec
  });

  $(document).mouseup(function(e) {
    var touchenabled =
      !!("ontouchstart" in window) ||
      !!("ontouchstart" in document.documentElement) ||
      !!window.ontouchstart ||
      !!window.Touch ||
      !!window.onmsgesturechange ||
      (window.DocumentTouch && window.document instanceof window.DocumentTouch);
    var target = $(e.target);

    // Close the navigation menu when there is a click on the page somewhere other than the menu button or within the menu
    if (
      !target.hasClass("menu-button") &&
      !target.parent().hasClass("menu-button") &&
      target.closest(".navigationRibbon").length === 0
    ) {
      $("#wp-top-navbar").removeClass("in");
    }
    // Close the search box when there is a click on the page somewhere other than the search box or within the search box
    if (
      !target.hasClass("search-button") &&
      !target.parent().hasClass("search-button") &&
      target.closest(".header-search").length === 0
    ) {
      $(".header-search").removeClass("in");
    }
  });
})(jQuery);
